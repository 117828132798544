.collapse {
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  &__title {
    background-color: #FF6060;
    border-radius: 5px;
    padding: 10px 20px 10px 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    color: white;
    font-size: 24px;
    font-weight: 500;
    box-sizing: border-box;
    height: 52px;
  }

  &__img {
    transform-origin: center;
    transition: transform 0.3s ease-out;

    width: 32px;
    height: 32px;

    &.rotate {
      transform: rotate(-180deg);
    }

  }
  
  &__img.rotate {
    transform: rotate(-180deg);
  }
  
  &__content {
    width: 100%;
    background-color: #F6F6F6;
    color: #FF6060;
    font-size: 18px;
    line-height: 145%;
    border-radius: 5px;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out, opacity 0.5s ease-out;
    opacity: 0;
    padding: 10px;
    box-sizing: border-box;
    margin-bottom: 15px;
    display: flex;
    align-items: flex-start;
  }
  
  &__content.open {
    max-height: 500px; 
    opacity: 1;
  }


  @media (max-width: 425px) {
    width: 335px;

    &__title {
      font-size: 18px;
      height: 32px;
    }

    &__content {
      font-size: 14px;
    }
  }
}

.collapse--about {
  max-width: 1023px;
  width: 100%;

  &__title {
    @media (max-width: 425px) {
      font-size: 13px;
    }

  }

  &__title, &__content {
    @media (max-width: 425px) {
      font-size: 13px;
    }
  }
}

.collapse--apartement {

  width: 582px;



  @media (max-width: 425px) {
    width: 335px;
  }
}
