.app{
    max-width: 1440px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    font-family: Montserrat;
}

.app__container{
    max-width: 1240px;
    width: 100%;   
    margin-bottom: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width:465px) {
        max-width: 335px;
        margin-bottom: 0;
    }
}
