.about-leader {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1230px;
  width: 100%;
}

.about {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  @media (max-width:425px) {
    width: 335px;
  }
}
