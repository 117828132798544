.slideshow {
  position: relative;
  width: 100%;
  height: 415px;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 50px;

  &__button {
    position: absolute;
    top: 50%; 
    transform: translateY(-50%); 
    background-color: transparent;
    border: none;
    cursor: pointer;

    img {
      width: 48px;
      height: 48px;
    }

    &--prev img {
      transform: rotate(180deg);
      margin-left: 10px;
    }

    &--next {
      right: 10px;
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__counter {
    position: absolute;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    color: white;   
    font-size: 18px;
    font-weight: 500;
  }

  // @media (max-width: 1024px) {
  //   height: 350px;

  //   &__button img {
  //     width: 36px;
  //     height: 36px;
  //   }
  // }

  @media (max-width: 768px) {
    height: 300px;
    width: 685px;

    &__button img {
      width: 30px;
      height: 30px;
    }
  }

  @media (max-width: 425px) {
    width: 335px;
    height: 255px;
    margin-left: auto;
    margin-right: auto;

    &__counter {
      display: none;
    }

    &__button {
      top: 50%; // Centrer verticalement les flèches

      img {
        width: 24px;
        height: 24px;
      }

      &--next {
        right: 10px; // Aligner horizontalement la flèche de droite
      }
    }
  }
}
