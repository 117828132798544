.property {
  &__title {
    font-size: 36px;
    font-weight: bold;
    color: #FF6060;
    font-style: normal;
    font-weight: 500;
    line-height: 142.6%;
    margin: 0;
    display: flex;
      flex-direction: column;
    

    @media (max-width: 465px) {
      font-size: 18px;
      display: flex;
      width: 335px;
    }
  }

  &__location {
    font-size: 18px;
    font-weight: 500;
    color: #FF6060;
    margin-top: 0;

    @media (max-width: 465px) {
      font-size: 14px;
    }
  }

  &__tags {
    display: flex;
    gap: 10px;
  }

  &__tag {
    width: auto;
    height: 25px;
    background-color: #FF6060;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    padding: 3px 10px;
    font-size: 14px;

    @media (max-width: 465px) {
      font-size: 10px;
      padding: 1px 15px;
    }
  }

    @media (max-width:465px) {
      
    }
}
