.apartement-rental {
  width: 100%;
  display: flex;
  flex-wrap: wrap;

  &__collapse {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
  }

  &__property {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin: 30px 0;
  }

  &__owner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items:flex-end;
    justify-content: flex-start;
    width: 100%;

    &__rating {
      order: 2;
    }

    &__host {
      order: 1;
    }
  }
  @media (max-width:768px) {
    justify-content: center;
    &__property{
      padding: 0 50px;
    }
    
  }
  @media (max-width: 465px) {
    flex-direction: column;
    align-content: center;

    &__collapse {
      flex-direction: column;
      width: 100%;
    }

    &__property {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      margin-top: 15px;
      padding: 0;
      margin-right: 0;
    }

    &__owner {
      order: 1;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
      width: 335px;

      &__rating {
        order: 1;
        margin: 0;
        
      }

      &__host {
        order: 2;
      }
    }
  }

  @media (max-width: 425px) {
    &__collapse {
      width: 335px;
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.equipment-item {
  display: block;
}
