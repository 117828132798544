.card {
  position: relative;
  width: 340px;
  height: 340px;
  border-radius: 10px;
  overflow: hidden;
  margin: 25px;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(transparent, rgba(0, 0, 0, 0.51));
    z-index: 2;
  }
  
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  &__content {
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    z-index: 3;
  }
  
  &__title {
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 142.6%; 
  }

  @media (max-width:1221px) {
    max-width: 700px;
    width: 100%;
    height: auto;
  }

  @media (max-width:768px) {
    max-width: 700px;
    width: 100%;
    height: auto;
    margin: 5px;
  }

  @media (max-width:425px) {
    width: 335px;
    height: 225px;
    margin: 5px;
  }
}
