.home {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    @media (min-width: 426px) {
      br {
        display: none;
      }
    }
  }
}

.home-banner {
  width: 100%;
  @media (max-width:465px) {
    width: 335px;
  }
}

.card-container {
  box-sizing: border-box;
  border-radius: 25px;
  max-width: 1240px;
  width: 100%;
  margin: 43px auto; 
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start; 
  background-color: #F6F6F6;
  padding: 26px;

  @media (max-width:1221px){
    justify-content: center;
  }

  @media (max-width:465px) {
    width: 355px;
    height: auto;
    background-color: transparent;
   
    margin: 0 0 20px 0;
    padding: 0;
  }
}
