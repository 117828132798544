$owner-font-family: Montserrat;
$owner-font-style: normal;
$owner-font-weight: 500;
$owner-line-height: 142.6%;
$owner-color: #FF6060;

.owner {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  
    &__name {
      margin-right: 10px;
      color: $owner-color;
      text-align: right;
      font-family: $owner-font-family;
      font-size: 18px;
      font-style: $owner-font-style;
      font-weight: $owner-font-weight;
      line-height: $owner-line-height;
    }
  
    &__picture {
      width: 64px;
      height: 64px;
      border-radius: 50px;
    }
  
    @media (max-width: 1024px) {
      &__name {
        font-size: 16px;
      }
  
      &__picture {
        width: 56px;
        height: 56px;
      }
    }
  
    @media (max-width: 768px) {
      &__name {
        font-size: 14px;
      }
  
      &__picture {
        width: 48px;
        height: 48px;
      }
    }
  
    @media (max-width: 425px) {
      &__name {
        font-size: 12px;
      }
  
      &__picture {
        width: 33px;
        height: 33px;
      }
    }
}
