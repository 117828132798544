.footer {
    background-color: black;
    width: 100%;
    height: 209px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    

    &__logo {
        margin-bottom: 10px;
    }

    &__text {
        font-size: 14px;
    }



}
